<template>
  <v-container
    id="accounts"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="12" class="text-center">
        <div class="text-h2 font-weight-medium">O que deseja fazer agora?</div>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" sm="6" class="text-center assistent-border">
        <v-row>
          <v-col cols="12" sm="12" class="text-center">
            <div class="text-h3 font-weight-normal">Cadastrar uma nova loja</div>
          </v-col>
          <v-col cols="12" sm="12" class="text-center mt-9 pt-16">
            <v-btn color="success" @click="cadastrarLoja">
              <i class="mdi mdi-plus-circle-outline font-size-24 mr-4" />
              Cadastrar agora
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="text-center">
        <v-row>
          <v-col cols="12" sm="12" class="text-center">
            <div class="text-h3 font-weight-normal">Revisar uma loja</div>
          </v-col>
          <v-col cols="12" sm="12" class="text-center mt-n5">
            <v-col cols="12" sm="6" offset-sm="3" class="text-center">
              <v-select
                    color="secondary"
                    item-color="secondary"
                    label="Escolha a loja para revisar"
                    :items="filteredAccounts"
                    v-model="selectedAccount"
                    :item-text="item => `${item.id} - ${item.account}`"
                    return-object
                  >
                  
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field label="Filtrar" v-model="accountsFilter" />
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn color="primary" @click="revisarLoja">
                <i class="mdi mdi-magnify-plus-outline font-size-24 mr-4 px-2" />
                Revisar agora
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AccountService } from '@/code/services/user/accountService';

  export default {

    data() {
      return {
        accountsList: [],
        selectedAccount: null,
        accountsFilter: ''
      }
    },

    mounted() {
      this.loadAccounts();
      this.selectedAccount = {};
    },

    methods: {
      loadAccounts: async function() {
        const service = new AccountService();
        this.accountsList = await service.getAllAccounts(true);
      },
      revisarLoja: function() {
        if (!this.selectedAccount || !this.selectedAccount.id || this.selectedAccount.id <= 0) {
          Swal.fire("Selecione uma loja para revisar");
        } else {
          this.$router.push({ name: 'assistentEditAccount', params: { id: this.selectedAccount.id }});
        }
      },
      cadastrarLoja: function() {
        this.$router.push({ name: 'assistentNewAccount' });
      },
    },

    computed: {
      filteredAccounts: function() {
        if (!this.accountsFilter) {
          return this.accountsList;
        } else {
          return this.accountsList.filter((el) => el.id.toString() === this.accountsFilter || el.account.toLowerCase().includes(this.accountsFilter.toLowerCase()));
        }
      }
    }

  }
</script>